import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect } from "react";
import "../styles/styles.scss";

const SuccessPage = () => {
  useEffect(()=>{
    window.scrollTo(0,1);
  },[])
  return (
    <div className="success-wrap">
      <div className="head-stuff">
        <div className="deco">
          <StaticImage
            placeholder="blurred"
            src="../images/flower.svg"
            alt="flower"
          />
        </div>
        <h2>Head over to your inbox for all the details!</h2>
      </div>
      <div className="rest-stuff">
        <StaticImage
          placeholder="blurred"
          src="../images/mmlogoblack.svg"
          alt="mmlogo"
        />
        <div className="award">
          <span>An award</span>
          <div className="roller">
            <StaticImage
              className="win"
              objectFit="contain"
              src="../images/winner.svg"
              alt="winner"
              placeholder="blurred"
            />
            <StaticImage
              className="cup"
              objectFit="contain"
              src="../images/cup.png"
              alt="cup"
              placeholder="blurred"
            />
          </div>
          <span>winning app</span>
        </div>
        <h3 className="reg">
          To add more magic to your store, give us a try on the shopify app
          store!{" "}
        </h3>
        <div className="revs">
          <StaticImage
            className="star"
            objectFit="contain"
            src="../images/star.svg"
            alt="star"
            placeholder="blurred"
          />
          &nbsp;
          <b>4.9</b>
          &ensp;
          <span className="reg">(314 reviews)</span>
        </div>
        <a
          href="https://apps.shopify.com/product-labels-badges-modemagic?&utm_source=landingpage&utm_medium=paid&utm_campaign=badgematch"
          className="slide-cta cta"
        >
          <div className="swiper">
            <StaticImage
              className="swipe"
              objectFit="contain"
              src="../images/swipes.svg"
              alt="swipe"
              placeholder="blurred"
            />
            <StaticImage
              className="swipe"
              objectFit="contain"
              src="../images/swipes.svg"
              alt="swipe"
              placeholder="blurred"
            />
          </div>
          Start Free Trial
        </a>
      </div>
    </div>
  );
};

export default SuccessPage;
